import camelCase from 'lodash.camelcase';
import { isAuthentified } from './auth';

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
const TRACKING_TYPE = process.env.REACT_APP_TRACKING_TYPE;

const debugEnabled = process.env.REACT_APP_DEBUG !== undefined && !!parseInt(process.env.REACT_APP_DEBUG);
if ( debugEnabled ) { console.log('environment', process.env); }

export const dataLayerPush = function() {
  window.dataLayer = window.dataLayer || [];
  // Can't use arrow func + destructuring as Google expects
  // arguments objects in dataLayer (not an array of arguments).
  window.dataLayer.push(arguments);
};

export const sendPageView = ({ path, title, initialize }) => {
  // if ( !initialize ) {
    dataLayerPush('config', TRACKING_ID, {
      'page_title': title,
      'page_path': path,
    });
  // }
};
export const sendEvent = ({ action, category, label, value }) => {
  dataLayerPush('event', action, {
    'event_category': category,
    'event_label': label,
    'value': value
  });
};
export const sendException = ({ description, fatal }) => {
  dataLayerPush('event', 'exception', { description, fatal });
};

export const install = (data = {}) => {
  const trackingId = TRACKING_ID;
  const trackingType = TRACKING_TYPE ? TRACKING_TYPE : 'gtag';
  const scriptId = `ga-${trackingType}`;

  if (document.getElementById(scriptId)) return;

  switch ( trackingType ) {
    case 'gtm':
      // dataLayerPush({
      //   'gtm.start': new Date().getTime(),
      //   event: 'gtm.js',
      // });
      break;
    default:
      dataLayerPush('js', new Date());
      dataLayerPush('config', trackingId, data);
  }

  const {head} = document;
  const script = document.createElement('script');
  script.id = scriptId;
  script.type = 'text/javascript';
  switch ( trackingType ) {
    case 'gtm':
      // script.src = `https://www.googletagmanager.com/gtm.js?id=${trackingId}`;
      script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${trackingId}');`;
      break;
    default:
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  }
  head.insertBefore(script, head.firstChild);
};

const eventFrontCategory = () => isAuthentified() ? 'EmagAbo' : 'EmagFree';

/**
 * Exported functions
 *  - normalizeIssueName
 *  - handleLog
 *  - handleException
 *  - handleViewerEvent
 *  - handleNavEvent
 *  - handleCustomEvent
 *  - analyticsTagEvent
 *  -
 *  - dataLayerPush
 *  - install
 *     - gtag
 *     - gtm
 */

export const normalizeIssueName = issue => {
  return `${issue.isFree ? 'FREE' : ( isAuthentified() ? 'ABO' : 'NOABO' )}-${issue.number}-${camelCase(issue.name)}`;
}

export const handleLog = (...args) => {
  if ( debugEnabled ) {
    console.log(...args);
  }
};

export const handleException = ({ error, ...data }) => {
  sendException({
    description: error,
    fatal: data.fatal ? data.fatal : true,
  });
};

export const handleViewerEvent = (event, data) => {
  handleLog('handleViewerEvent', event, data);
  switch ( event ) {
    case 'open':
      if ( data.issue && data.issue['@id'] ) {
        // sendPageView( { path: `${data.issue['@id']}`, title: data.issue.name } );
        sendEvent({ action: 'ClicReadEmag', category: eventFrontCategory(), label: normalizeIssueName(data.issue), value: 1 });
      }
      break;
    case 'close':
      if ( data.issue && data.issue['@id'] ) {
        // sendPageView( { path: `${data.issue['@id']}`, title: data.issue.name } );
        sendEvent({ action: 'CloseEmag', category: 'EmagViewer', label: `${data.issue.number}-${data.page ? `${data.page.position}-${camelCase(data.page.name)}` : '?'}`, value: 1 });
      }
      break;
    case 'pageView':
      if ( data.issue && data.page ) {
        // sendPageView( { path: `${data.issue['@id']}/${data.page['@id']}`, title: data.page.name } );
        sendEvent({ action: 'PageView', category: 'EmagViewer', label: `${data.issue.number}-${data.page.position}-${camelCase(data.page.name)}`, value: 1 });
      }
      break;
    case 'regionClick':
      if ( data.issue && data.page && data.region ) {
        // sendPageView( { path: `${data.issue['@id']}/${data.page['@id']}`, title: data.page.name } );
        sendEvent({ action: `R${camelCase(`egion-click-${data.region.type}`)}`, category: 'EmagViewer', label: `${data.issue.number}-${data.page.position}-${camelCase(data.page.name)}-${data.region.id}`, value: 1 });
      }
      break;
    case 'error':
      handleException(data);
      break;
    default:
  }
};

export const handleNavEvent = (event, data) => {
  handleLog('handleNavEvent', event, data);
  switch ( event ) {
    case 'nav':
      if ( data.url ) { sendPageView( { path: data.url, title: '', initialize: data.initialize } ); }
      break;
    default:
  }
};

export const handleCustomEvent = (event, data) => {
  handleLog('handleCustomEvent', event, data);
  switch ( event ) {
    case 'external':
      if ( data.url ) { sendEvent({ action: data.url, category: eventFrontCategory(), label: 'ExternalLink', value: 1 }); }
      break;
    case 'newsletter':
      sendEvent({ action: 'ClicNewsletter', category: eventFrontCategory(), label: 'Subscribe', value: 1 });
      break;
    default:
      sendEvent({ action: data.action, category: eventFrontCategory(), label: data.label, value: 1 });
  }
};

export const analyticsTagEvent = ({ action, label }) => {
  handleCustomEvent('tag', { action, label });
}
