export const APP = {
  name: 'webdoc-wapiti',
  url: 'https://webdoc.wapiti-magazine.com/',
  colors: {
    main: '#26bdd6',
    accent: '#e96000',
    loader: '#fff',
  },
  issue: '/issues/135',
};
