import React, { Fragment } from 'react';

import 'font-awesome/css/font-awesome.css';

import { APP } from './config';
import Issue from './components/Issue';

import './css/app.scss';

export default () => {
  let issue = APP.issue;
  if (window && window.location && window.location.search && URLSearchParams) {
    const urlParams = new URLSearchParams(window.location.search);
    const issueParam = urlParams.get('issue');
    if (issueParam) {
      issue = issueParam.indexOf('/issues/') > -1 ? issueParam : `/issues/${issueParam}`;
    }
  }
  return (
    <Fragment>
      <Issue id={issue} />
    </Fragment>
  );
};
