import { ENTRYPOINT } from '../config';
import has from 'lodash/has';
import qs from 'qs';

import { fetchHeaders } from './auth';
import { handleLog as log } from './analytics';

const MIME_TYPE = 'application/ld+json';

class DataAccessError extends Error {
  constructor(code = 401, message = 'Error', ...params) {
    super(message);
    if(Error.captureStackTrace) {
      Error.captureStackTrace(this, DataAccessError);
    }
    this.name = 'DataAccessError';
    this.code = code;
    this.message = message;
    this.data = params;
    this.date = new Date();
  }
}

export function fetch(resource, options = {}) {
  const newOptions = Object.assign({}, options);
  if ('undefined' === typeof newOptions.headers) {
    newOptions.headers = new Headers(fetchHeaders());
  }
  else {
    const authHeaders = fetchHeaders();
    newOptions.headers.set('Authorization', authHeaders['Authorization'] ? authHeaders['Authorization'] : '');
  }

  if (null === newOptions.headers.get('Accept')) {
    newOptions.headers.set('Accept', MIME_TYPE);
  }

  if (
    'undefined' !== newOptions.body &&
    !(newOptions.body instanceof FormData) &&
    null === newOptions.headers.get('Content-Type')
  ) {
    newOptions.headers.set('Content-Type', MIME_TYPE);
  }

  log(`fetching ${resource}...`, newOptions.headers ? [ ...newOptions.headers.values() ] : null, newOptions.params);

  const url = new URL(resource, ENTRYPOINT);
  if ( 'undefined' !== newOptions.params ) {
    url.search = qs.stringify(newOptions.params);
    delete newOptions.params;
  }

  return global.fetch(url, newOptions).then(response => {
    if (response.ok) return response;

    return response.json().then(json => {
      const error = json['hydra:description'] || response.statusText;
      if (!json.violations) throw new DataAccessError(response.status, error);

      let errors = { _error: error };
      json.violations.map(
        violation => (errors[violation.propertyPath] = violation.message)
      );

      throw new Error(errors);
    });
  });
}

export function mercureSubscribe(url, topics) {
  topics.forEach(topic =>
    url.searchParams.append('topic', new URL(topic, ENTRYPOINT))
  );

  return new EventSource(url.toString());
}

export function normalize(data) {
  if (has(data, 'hydra:member')) {
    // Normalize items in collections
    data['hydra:member'] = data['hydra:member'].map(item => normalize(item));

    return data;
  }

  // Flatten nested documents
  return data; /*mapValues(data, value =>
    Array.isArray(value)
      ? value.map(v => get(v, '@id', v))
      : get(value, '@id', value)
  );*/
}

export function extractHubURL(response) {
  const linkHeader = response.headers.get('Link');
  if (!linkHeader) return null;

  const matches = linkHeader.match(
    /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
  );

  return matches && matches[1] ? new URL(matches[1], ENTRYPOINT) : null;
}
