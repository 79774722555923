import React, { Component, Fragment } from 'react';
import { IssueViewer } from 'react-emag-viewer';

import { fetchHeaders, handleViewerEvent } from '../utils';

import { APP, ENTRYPOINT } from '../config';

class Issue extends Component {
  render() {
    const { id } = this.props;
      return (
        <Fragment>
          {id ? (
            <article className="issue--item">
              <IssueViewer issueId={id} entrypoint={ENTRYPOINT} headers={fetchHeaders}
                debug={false}
                modalParams={{ colors: APP.colors }}
                loaderParams={{ background: 'transparent', color: APP.colors.accent }}
                errorMessage={() => <div className="issue-viewer-error"><p>Accès refusé</p></div>}
                onEvent={handleViewerEvent}
                button={<button className="btn">Lire l'emag</button>}
                extra={<Fragment></Fragment>}
                closeLabel=""
                alwaysOpen={true}
              />
            </article>
          ) : <div className="alert alert-danger">ERREUR</div>}
        </Fragment>
      )
  }
}

export default Issue;
